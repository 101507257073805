<template>
  <div class="row">
    <div class="col-md-12">
      <card class="stacked-form">
        <form>
          <!-- form -->
          <div class="row">
            <div class="col-md-12">
              <label for="type">Tipo do Produto</label>
              <select
                id="type"
                v-model="form.type"
                class="form-control custom-select"
              >
                <option value="" disabled selected>Selecione</option>
                <option value="simple">Produto Simples</option>
                <option value="variation">Produto com Variação</option>
              </select>
            </div>
          </div>

          <div class="row" v-if="form.type === 'variation'">
            <div class="col-md-12">
              <div class="row" v-if="showVariations">
                <div class="col-md-2">
                  <h5><b>Variações</b></h5>
                </div>

                <div class="col-md-4">
                  <h5><b>Preço</b></h5>
                </div>
                <div class="col-md-4">
                  <h5><b>Estoque</b></h5>
                </div>
                <div class="col-md-2"></div>
              </div>
              <template v-for="(value, index) in form.variations">
                <div class="row" :key="index" v-if="value.status === 1">
                  <div class="col-md-2">
                    {{ value.name }}: {{ value.value }}
                  </div>

                  <div class="col-md-4">
                    <base-input v-model="value.price"></base-input>
                  </div>
                  <div class="col-md-4">
                    <base-input v-model="value.stock"></base-input>
                  </div>
                  <div class="col-md-2 product-trash">
                    <i class="fas fa-trash" @click="removeVariation(index)"></i>
                  </div>
                </div>
              </template>
            </div>

            <div class="col-md-5 mt-1">
              <h5><b>Adcionar Variação</b></h5>

              <label for="type">Tipo da Variação</label>
              <select
                id="type"
                v-model="variation.name"
                class="form-control custom-select"
              >
                <option value="" disabled selected>Selecione</option>
                <option value="tamanho">Tamanho</option>
              </select>
            </div>

            <div class="col-md-5" v-if="variation.name === 'tamanho'">
              <label for="type">Selecione o tamanho</label>
              <select
                id="type"
                v-model="variation.value"
                class="form-control custom-select"
              >
                <option value="" disabled selected>Selecione</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="G">G</option>
              </select>
            </div>

            <div
              class="col-md-2 pt-3 mt-1"
              v-if="variation.name && variation.value"
            >
              <button
                class="btn btn-secondary btn-block"
                @click.prevent.stop="addVariation()"
              >
                Adicionar
              </button>
            </div>

            <div class="divider"></div>
          </div>

          <div class="row" v-if="form.type !== ''">
            <div
              :class="{
                'col-md-4': form.type === 'simple',
                'col-md-6': form.type === 'variation'
              }"
            >
              <base-input
                label="Nome do Produto"
                v-model="form.name"
              ></base-input>
              <!-- <span class="input-count" :class="{'text-danger':form.name.length >= 60 }">{{form.name.length}}</span> -->
            </div>

            <div class="col-md-4" v-if="form.type === 'simple'">
              <base-input
                label="Código de Barras (EAN)"
                v-model="form.ean"
              ></base-input>
            </div>

            <div
              :class="{
                'col-md-4': form.type === 'simple',
                'col-md-6': form.type === 'variation'
              }"
            >
              <label for="type">Categoria</label>
              <select
                id="type"
                v-model="form.category_id"
                class="form-control custom-select"
              >
                <option value="" disabled selected>Selecione</option>
                <option
                  v-for="category in categories"
                  :key="category.id"
                  :value="category.id"
                  >{{ category.title }}</option
                >
              </select>
            </div>

            <div class="col-md-4">
              <base-input label="Marca" v-model="form.brand"></base-input>
            </div>

            <div class="col-md-4">
              <base-input label="Modelo" v-model="form.model"></base-input>
            </div>

            <div class="col-md-4">
              <label for="color">Cor</label>
              <select
                id="color"
                v-model="form.colors"
                class="form-control custom-select"
              >
                <option value="" disabled selected>Selecione</option>
                <option
                  :key="index"
                  v-for="(color, index) in colors"
                  :value="color.id"
                  >{{ color.name }}</option
                >
              </select>

              <!-- <el-select multiple class="select-info" size="large" v-model="form.colors" collapse-tags placeholder="Multiple Select">
                            <el-option v-for="option in colors" class="select-info" :value="option.id" :label="option.name" :key="option.name">
                            </el-option>
                        </el-select> -->
            </div>

            <div class="col-md-6" v-if="form.type === 'simple'">
              <base-input
                label="Estoque Físico"
                v-model="form.stock"
              ></base-input>
            </div>

            <div class="col-md-6" v-if="form.type === 'simple'">
              <base-input
                label="Preço do Produto"
                v-model="form.price"
              ></base-input>
            </div>

            <div class="col-md-12">
              <label for="description"
                >Descrição do produto (máximo 2000 caracteres)</label
              >
              <textarea
                id="description"
                class="form-control"
                v-model="form.description"
              ></textarea>
              <!-- <span class="input-count" :class="{'text-danger':form.description.length >= 2000 }">{{form.description.length}}</span> -->
            </div>

            <div class="col-md-12 mt-4">
              <label>Imagens do produto</label>
              <div class="upload-container">
                <template v-for="(image, index) in form.images">
                  <div
                    class="upload-preview"
                    :style="'background: url(' + image.image + ')'"
                    :key="index"
                    v-if="image.image !== ''"
                  >
                    <div class="remove-file" @click="removeImage(index)">
                      <p><i class="fa fa-times"></i></p>
                      <p>Remover</p>
                    </div>
                  </div>
                </template>
                <div class="add-file">
                  <label for="image">
                    Adicionar <i class="fas fa-plus"></i>
                  </label>
                  <input @change.prevent="addImage" type="file" id="image" />
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <label for="">Status</label>
              <base-radio v-model="form.status" inline name="1"
                >Ativo</base-radio
              >
              <base-radio v-model="form.status" inline name="0"
                >Inativo</base-radio
              >
              <base-radio v-model="form.status" inline name="2"
                >Indisponível</base-radio
              >
            </div>
          </div>

          <!-- botoes -->
          <div class="col-md-12 px-0 mt-1">
            <base-button
              class="mt-3"
              @click.prevent.stop="goBack()"
              type="danger"
              >Cancelar</base-button
            >
            <base-button class="mt-3" @click.prevent="submit(-1)" type="warning"
              >Salvar</base-button
            >
            <base-button class="mt-3" @click.prevent="submit(1)" type="success"
              >Salvar e Enviar</base-button
            >
          </div>
        </form>
      </card>
    </div>
  </div>
</template>
<script>
import { BaseRadio } from '@/components/index'

import swal from 'sweetalert2'

export default {
  data: () => ({
    form: {
      status: 1,
      volume: 1,
      type: '',
      variations: [],
      images: []
    },
    variation: {
      name: '',
      value: '',
      status: 1
    },
    colors: [],
    categories: []
  }),
  methods: {
    addVariation () {
      const variation = {
        name: this.variation.name,
        value: this.variation.value,
        status: 1
      }
      this.form.variations.push(variation)
      this.variatin = {
        name: '',
        value: '',
        status: 1
      }
    },
    removeVariation (index) {
      if (this.form.variations[index].id === null) {
        this.form.variations.splice(index, 1)
      } else {
        this.form.variations[index].status = -1
      }
    },
    async getData () {
      const id = this.$route.params.id || false
      if (id) {
        this.form = (await this.$http.get(`products/${id}`)).data
        this.form.colors = this.form.colors[0].id
        if (!this.form.images) this.form.images = []
        else {
          for (var i = 0; i < this.form.images.length; i++) {
            this.form.images[i].image = this.form.images[i].link
          }
        }
      }
    },
    async getColors () {
      this.colors = (await this.$http.get('colors')).data
    },
    async getCategories () {
      this.categories = (await this.$http.get('categories/type/Produtos')).data
    },
    async submit (draft) {
      this.form.draft = draft
      if (this.validate()) {
        const id = this.$route.params.id || false
        let status
        try {
          if (id) {
            status = (await this.$http.put(`products/${id}`, this.form)).status
          }
          if (!id) {
            status = (await this.$http.post('products', this.form)).status
          }
        } catch (e) {
          status = e.response.status
        }

        if (status === 200) {
          swal({
            title: 'Salvo!',
            text: `"${this.form.name}" salvo com sucesso.`,
            type: 'success',
            showConfirmButton: false,
            timer: 2000
          })
          this.goBack()
        } else {
          swal({
            title: 'Erro!',
            text: 'Ocorreu um erro, tente novamente mais tarde.',
            type: 'error',
            confirmButtonClass: 'btn btn-danger btn-fill',
            confirmButtonText: 'Ok',
            showConfirmButton: true
          })
        }
      } else {
        swal({
          title: 'Erro!',
          text: 'Por favor, preencha todos os campos obrigatórios.',
          type: 'error',
          confirmButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: 'Ok',
          showConfirmButton: true
        })
      }
    },
    goBack () {
      window.history.go(-1)
    },
    addImage (e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      const vue = this
      const file = files[0]
      var reader = new FileReader()

      reader.onload = e => {
        vue.form.images.push({
          image: e.target.result
        })
      }
      reader.readAsDataURL(file)
    },
    removeImage (index) {
      const images = this.form.images
      const newimages = []
      for (var i = 0; i < images.length; i++) {
        if (i !== index) {
          newimages.push(images[i])
        }
      }
      this.form.images = newimages
    },

    validate () {
      const form = this.form
      if (!form.title || !form.title.trim()) return false
      if (!form.image || form.image === '') return false
      if (!form.content || form.content === '') return false
      if (!form.category_id || form.category_id === '') return false
      return true
    }
  },
  mounted () {
    this.getData()
    this.getColors()
    this.getCategories()
  },
  computed: {
    showVariations () {
      if (!this.form.variations.length) return false
      if (this.form.variations.length) {
        for (var i = 0; i < this.form.variations.length; i++) {
          if (this.form.variations[i].status === 1) return true
        }
        return false
      }
      return false
    }
  },
  components: {
    BaseRadio
  }
}
</script>
<style></style>
